import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectFilterLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectZona', method: 'zona.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTactuacion', method: 'tactuacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTsistema', method: 'tsistema.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTmotivoBloqueoOt', method: 'tmotivoBloqueoOt.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTecnico', method: 'tecnico.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectAsignados (Vue, filter, search, sorter, page, idtecnico, estado, mapBounds, pageSize) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', estado)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('cliente_nombre', search)
        .addILike('sistema_descripcion', search)
      if (!isNaN(search)) {
        apiFilterSearch
          .addExact('idparte_trabajo', search)
          .addExact('norden_trabajo', search)
      }
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.clienteIdcliente.value) {
      apiFilter.addExact(filter.clienteIdcliente.field, filter.clienteIdcliente.value)
    }
    if (filter.sistemaCodigo.value) {
      apiFilter.addILike(filter.sistemaCodigo.field, filter.sistemaCodigo.value)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.zona.value) {
      apiFilter.addExact(filter.zona.field, filter.zona.value)
    }
    if (filter.tactuacion.value) {
      apiFilter.addExact(filter.tactuacion.field, filter.tactuacion.value)
    }
    if (filter.tsistema.value) {
      apiFilter.addExact(filter.tsistema.field, filter.tsistema.value)
    }
    if (filter.clasificacion.value !== null) {
      apiFilter.addExact(filter.clasificacion.field, filter.clasificacion.value)
    }
    if (filter.tmotivoBloqueoOt.value) {
      apiFilter.addExact(filter.tmotivoBloqueoOt.field, filter.tmotivoBloqueoOt.value)
    }
    if (filter.tecnico.value) {
      // si filtra por técnico sobreescribe el parámetro idtecnico
      idtecnico = filter.tecnico.value
    }
    apiFilter.addExact('idtecnico', idtecnico)
    if (filter.conFfijada.value !== null) {
      apiFilter.addExact(filter.conFfijada.field, filter.conFfijada.value)
    }
    if (filter.ffijada.value) {
      apiFilter
        .addGTE(filter.ffijada.field, toDate(filter.ffijada.value))
        .addLTE(filter.ffijada.field, endOfDay(toDate(filter.ffijada.value)))
    }
    if (filter.ffinparte.value) {
      apiFilter
        .addGTE(filter.ffinparte.field, toDate(filter.ffinparte.value))
        .addLTE(filter.ffinparte.field, endOfDay(toDate(filter.ffinparte.value)))
    }
    if (filter.sistemaDireccion.value) {
      apiFilter.addILike(filter.sistemaDireccion.field, filter.sistemaDireccion.value)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('parteTrabajo.selectAsignados', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectAsignadosRows (Vue, pks, idtecnico, estado) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', estado)
      .addIn('idparte_trabajo', pks)
      .addExact('idtecnico', idtecnico)
    const resp = await Vue.$api.call(
      'parteTrabajo.selectAsignados',
      { filter: apiFilter }
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
